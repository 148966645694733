<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>BAHAN MASUK</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Dashboard </a></li>
                                <li class="breadcrumb-item active">Bahan Masuk</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12  wow fadeInDown"
                            v-if="(leveluser == 0) || (leveluser == 1) || (leveluser == 4)">
                            <div class="card collapsed-card">
                                <div class="card-header border-transparent" data-card-widget="collapse"
                                    style="cursor: pointer;">
                                    <label>Import Data Excel</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="col-sm-12">
                                        <div class="col-sm-12 text-center">
                                            <a class="hitam">
                                                <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                                    data-toggle="tooltip" data-placement="left"
                                                    title="Unduh master excel.." @click="G_tableCSV('_BarangMasuk_')">
                                                    <i class="fas fa-download"></i> Unduh Master Data Barang Masuk .xlsx
                                                </button>
                                            </a>
                                        </div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12">
                                            <label>Pilih File<small class="text-info"> (*.xlsx)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_excel" name="fileToUploadfilenya_excel"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    @change="onChange">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_excel" name="filenya_excel" placeholder="File Excel">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-10">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_excel">
                                                            <span id="tulisanpersenfilenya_excel">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_excel"></span>
                                                </div>
                                                <div class="col-sm-2 text-center" v-if="tempjumlah > 0">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadsavedata()">
                                                        Simpan ke DB
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-12">
                                            Jumlah Data excel siap Import: {{ tempjumlah }}
                                            <span style="float: right;">Jumlah Data excel Tersimpan di DB: {{
                                                tempjumlahdb
                                            }}</span>
                                            <div class="table-responsive table-wrapper" v-if="tempjumlah > 0">
                                                <table class="table m-0" id="tabeldata2">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Urut
                                                            </th>
                                                            <template v-for="(dataheader, urutheader) in tempheader"
                                                                :key="urutheader">
                                                                <th>
                                                                    {{ dataheader }}
                                                                </th>
                                                            </template>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datadata, urutdata) in tempdata" :key="urutdata">
                                                        <tr>
                                                            <td>{{ urutdata + 1 }}</td>
                                                            <td v-for="(isidata, urutisi) in datadata" :key="urutisi">
                                                                {{ isidata }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        Jumlah Data {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            <span>Halaman ke: </span>
                                            <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select>
                                            <span> dari </span> {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata" style="font-size: small;">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;" rowspan="2">No</th>
                                                    <th style="width: 100px;">Tanggal</th>
                                                    <th style="width: 100px;">
                                                        Jenis Barang
                                                    </th>
                                                    <th style="width: 50px;" rowspan="2">Satuan</th>
                                                    <th style="width: 100px;" rowspan="2">Volume</th>
                                                    <th style="width: 100px;" rowspan="2">Harga Satuan (Rp.)</th>
                                                    <th style="width: 100px;" rowspan="2">Jumlah Harga (Rp.)</th>
                                                    <th style="width: 100px;">UPTD</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 100px;">
                                                        <input type="date" v-model="tanggalmasuk" class="form-control form-control-sm"
                                                        placeholder="Tanggal" @change="ambildata()">
                                                    </th>
                                                    <th style="width: 100px;">
                                                        <input type="text" v-model="jenisbarang" class="form-control form-control-sm"
                                                        placeholder="Jenis Barang" @keyup="ambildata()">
                                                    </th>
                                                    <th style="width: 100px;">
                                                        <input type="text" v-model="uptd" class="form-control form-control-sm"
                                                        placeholder="UPTD" @keyup="ambildata()">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ G_formatDateNonWIB(datalist.tanggalmasuk) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.jenisbarang }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.satuan }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numKoma(datalist.volume) }}
                                                        </td>
                                                        <td class="text-right">
                                                            {{ G_numFormat(datalist.hargasatuan) }}
                                                        </td>
                                                        <td class="text-right">
                                                            {{ G_numFormat(datalist.harga) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.uptd }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    Data tidak diketemukan
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import xlsx from 'xlsx/dist/xlsx.full.min';
import readXlsxFile from 'read-excel-file';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            tanggalmasuk: '',
            jenisbarang: '',
            uptd: '',
            tempheader: [],
            tempdata: [],
            tempjumlah: 0,
            tempjumlahdb: 0,
            folder_excel: process.env.VUE_APP_URL_API + 'parastapainnovation_excel-DownloadExcel?f=',
            tempexcel: [],
            ekportexcel: [],
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_barang-AllMasuk?random=" + random + "&tanggalmasuk=" + this.tanggalmasuk + "&jenisbarang=" + this.jenisbarang+ "&uptd=" + this.uptd).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.tempexcel = [];
                        this.tempexcel = Response.data.content.data;
                        for (let i = 0; i < this.tempexcel.length; i++) {
                            this.ekportexcel.push({
                                "No": i + 1,
                                "Tanggal PO / Order": this.tempexcel[i].tanggalmasuk,
                                "Jenis Barang": this.tempexcel[i].jenisbarang,
                                "Volume": this.tempexcel[i].volume,
                                "Satuan": this.tempexcel[i].satuan,
                                "Harga Satuan (Rp)": this.tempexcel[i].hargasatuan,
                                "Jumlah Harga (Rp)": this.tempexcel[i].harga,
                                "KA UPT/SUB KOR": this.tempexcel[i].uptd,
                            });
                        }
                        function formatColumn(worksheet, col, fmt) {
                            const range = XLSX.utils.decode_range(worksheet['!ref'])
                            // note: range.s.r + 1 skips the header row
                            for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                                const ref = XLSX.utils.encode_cell({ r: row, c: col })
                                if (worksheet[ref] && worksheet[ref].t === 'n') {
                                    worksheet[ref].z = fmt
                                }
                            }
                        }
                        const currency = '0.00';
                        const XLSX = xlsx;
                        const workbook = XLSX.utils.book_new();
                        const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

                        // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
                        for (let col of [3,5,6]) {
                            formatColumn(worksheet, col, currency)
                        }
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Barang Masuk");
                        XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
                        this.halamanloading = false;
                    }
                    else {
                        this.tempexcel = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                //this.ClearlocalStorage();
                this.halamanloading = false;
            });
            //console.log(this.datamaster);
            //return false;
        },
        onChange(event) {
            this.tempjumlahdb = 0;
            try {
                if (event.target.files[0]['type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    this.file = event.target.files ? event.target.files[0] : null;
                    if (this.file) {
                        this.tempheader = [];
                        this.tempdata = [];
                        readXlsxFile(this.file).then((rows) => {
                            // `rows` is an array of rows
                            // each row being an array of cells.
                            //alert(rows.length);
                            this.halamanloading = true;
                            this.tempjumlah = rows.length - 1;
                            //alert(this.tempjumlah);
                            for (let i = 0; i < rows.length; i++) {
                                this.halamanloading = true;
                                if (i == 0) {
                                    var varheader = rows[i];
                                    for (let x = 0; x < varheader.length; x++) {
                                        this.tempheader.push(varheader[x]);
                                    }
                                }
                                else {
                                    this.tempdata.push(rows[i]);
                                }
                                document.getElementById('persennyafilenya_excel').style.width = Math.round((i / (rows.length - 1)) * 100) + "%";
                                document.getElementById('tulisanpersenfilenya_excel').innerHTML = Math.round((i / (rows.length - 1)) * 100) + "%";
                            }
                            //alert(JSON.stringify(this.tempheader));
                            //console.log(this.tempdata);
                            this.halamanloading = false;
                        })
                    }
                }
                else {
                    swal.fire('Peringatan', 'File tidak sesuai format', 'error');
                    return false;
                }
            } catch (error) {

            }

        },
        async uploadsavedata() {
            this.tempjumlahdb = 0;
            var tempheader = this.tempheader;
            //console.log(JSON.stringify(tempheader));
            //alert(this.tempdata.length);
            //return false;
            var temparraydata = ''
            for (let i = 0; i < this.tempdata.length; i++) {
                var data = "";
                for (let x = 0; x < this.tempdata[i].length; x++) {
                    if (x == 0) {
                        data = this.tempdata[i][x];
                    }
                    else {
                        data = data + '|' + this.tempdata[i][x];
                    }
                }
                if (i == 0) {
                    temparraydata = temparraydata + data;
                }
                else {
                    temparraydata = temparraydata + '|+|' + data;
                }
            }
            //console.log(temparraydata);
            //return false;
            var fd = new FormData();
            fd.append("tempheader", tempheader);
            fd.append("data", temparraydata);
            fd.append("createdby", this.iduser);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            this.halamanloading = true;
            await mainAPI.post("parastapainnovation_excel-SaveBarangMasuk", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        this.halamanloading = false;
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        this.halamanloading = false;
                        swal.fire('Informasi', 'Data telah diimport..', 'success').then(function () {
                            window.location.replace("/BahanMasuk");
                        });

                    }
                }
            ).catch(function (error) {
                this.halamanloading = false;
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            //alert(tempheader);
            //alert(acuan);
            //alert(data);
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_barang-DetailMasuk?random=" + random + "&tanggalmasuk=" + this.tanggalmasuk + "&jenisbarang=" + this.jenisbarang+ "&uptd=" + this.uptd+ "&halaman=" + halamannya).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                //this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_barang-DetailMasuk?random=" + random + "&tanggalmasuk=" + this.tanggalmasuk + "&jenisbarang=" + this.jenisbarang+ "&uptd=" + this.uptd).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                //this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>