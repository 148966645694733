<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar elevation-4 sidebar-dark-marron">
        <a href="#" class="brand-link animation__shake wow fadeInDown"
            style="align-items: center;position: absolute;top: 25px;" data-widget="pushmenu" role="button">
            <center>
                <i class="fas fa-align-right text-white"></i>&nbsp;<img src="../../../assets/img/gis2putih.png"
                    alt="Admin Logo" class="animation__shake wow fadeInDown" style="opacity: .8;height: 30px;">
            </center>
        </a>
        <!-- Sidebar -->
        <div class="sidebar wow fadeInLeft">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">
                        <router-link to="/Dashboard"
                            :class="[(route.name == 'Dashboard' ? 'nav-link active' : 'nav-link')]" id="Dashboard"><i
                                class="nav-icon fas fa-chart-line"></i>
                            <p>DASHBOARD</p>
                        </router-link>
                    </li>
                    <li
                        :class="[((route.name == 'BahanMasuk' || route.name == 'ProsesBahanMasuk' || route.name == 'BahanKeluar'
                            || route.name == 'ProsesBahanKeluar' || route.name == 'BahanPO'
                            || route.name == 'ProsesBahanPO') ? 'nav-item menu-open' : 'nav-item')]">
                        <a href="#"
                            :class="[((route.name == 'BahanMasuk' || route.name == 'ProsesBahanMasuk' || route.name == 'BahanKeluar'
                            || route.name == 'ProsesBahanKeluar')  ? 'nav-link active' : 'nav-link')]">
                            <i class="nav-icon fas fa-list-alt"></i>
                            <p>
                                BAHAN LOGISTIK
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/BahanMasuk"
                                    :class="[((route.name == 'BahanMasuk' || route.name == 'ProsesBahanMasuk') ? 'nav-link active' : 'nav-link')]"
                                    id="BahanMasuk"><i class="nav-icon 	fas fa-long-arrow-alt-down"></i>
                                    <p>BAHAN MASUK</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/BahanPO"
                                    :class="[((route.name == 'BahanPO' || route.name == 'ProsesBahanPO') ? 'nav-link active' : 'nav-link')]"
                                    id="BahanPO"><i class="nav-icon 	fas fa-box"></i>
                                    <p>PENGAJUAN MATERIAL</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/BahanKeluar"
                                    :class="[((route.name == 'BahanKeluar' || route.name == 'ProsesBahanKeluar') ? 'nav-link active' : 'nav-link')]"
                                    id="BahanKeluar"><i class="nav-icon fas fa-long-arrow-alt-up"></i>
                                    <p>BAHAN KELUAR</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li
                        :class="[((route.name == 'User' || route.name == 'ProsesUser' || route.name == 'Slider'
                            || route.name == 'ProsesSlider' || route.name == 'InfoBeranda' || route.name == 'ProsesInfoBeranda'
                            || route.name == 'Regulasi' || route.name == 'ProsesRegulasi'
                            || route.name == 'KategoriData' || route.name == 'ProsesKategoriData'
                            || route.name == 'LayerPeta' || route.name == 'ProsesLayerPeta' || route.name == 'DetailLayerPeta'
                            || route.name == 'Dokumen' || route.name == 'ProsesDokumen' || route.name == 'DetailDokumen') ? 'nav-item menu-open' : 'nav-item')]">
                        <a href="#"
                            :class="[((route.name == 'User' || route.name == 'ProsesUser' || route.name == 'Slider'
                                || route.name == 'ProsesSlider' || route.name == 'InfoBeranda' || route.name == 'ProsesInfoBeranda'
                                || route.name == 'Regulasi' || route.name == 'ProsesRegulasi'
                                || route.name == 'KategoriData' || route.name == 'ProsesKategoriData'
                                || route.name == 'LayerPeta' || route.name == 'ProsesLayerPeta' || route.name == 'DetailLayerPeta'
                                || route.name == 'Dokumen' || route.name == 'ProsesDokumen' || route.name == 'DetailDokumen') ? 'nav-link active' : 'nav-link')]">
                            <i class="nav-icon fas fa-tablets"></i>
                            <p>
                                PENGATURAN
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/Slider"
                                    :class="[((route.name == 'Slider' || route.name == 'ProsesSlider') ? 'nav-link active' : 'nav-link')]"
                                    id="Slider"><i class="nav-icon fas fa-photo-video"></i>
                                    <p>SLIDER GAMBAR</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/InfoBeranda"
                                    :class="[((route.name == 'InfoBeranda' || route.name == 'ProsesInfoBeranda') ? 'nav-link active' : 'nav-link')]"
                                    id="InfoBeranda"><i class="nav-icon fas fa-box"></i>
                                    <p>INFO BERANDA</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/Regulasi"
                                    :class="[((route.name == 'Regulasi' || route.name == 'ProsesRegulasi') ? 'nav-link active' : 'nav-link')]"
                                    id="Regulasi"><i class="nav-icon far fa-file-pdf"></i>
                                    <p>REGULASI</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/KategoriData"
                                    :class="[((route.name == 'KategoriData' || route.name == 'ProsesKategoriData') ? 'nav-link active' : 'nav-link')]"
                                    id="KategoriData"><i class="nav-icon fas fa-boxes"></i>
                                    <p>KATEGORI DATA PETA</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/LayerPeta"
                                    :class="[((route.name == 'LayerPeta' || route.name == 'ProsesLayerPeta') ? 'nav-link active' : 'nav-link')]"
                                    id="LayerPeta"><i class="nav-icon fas fa-layer-group"></i>
                                    <p>LAYER PETA</p>
                                </router-link>
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click="keluar()">
                            <i class="nav-icon fas fa-sign-out-alt"></i>
                            <p>KELUAR / LOGOUT</p>
                        </a>
                    </li>
                    <li>&nbsp;</li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
export default {
    components: {
        swal
    },
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
        };
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                localStorage.removeItem('tokensistem');
                localStorage.removeItem('usistem');
                localStorage.removeItem('lsistem');
                localStorage.removeItem('nmlsistem');
                localStorage.removeItem('emlsistem');
                localStorage.removeItem('nmusistem');
                localStorage.removeItem('tsistem');
                localStorage.removeItem('orsistem');
                localStorage.removeItem('bdlsistem');
                localStorage.removeItem('sbdsistem');
                localStorage.removeItem('jbtsistem');
            }
        },
        keluar() {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Keluar Aplikasi?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#ad3335",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.ClearlocalStorage();
                }
            });

            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if ((localStorage.getItem("tokensistem") == null)) {
            window.location.replace("/Login");
        }
    }
}
</script>

<style></style>